var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30",style:({
      backgroundImage: ("url(" + _vm.backgroundImage + ")")
    })},[_c('h1',{staticClass:"font-size-sm-100 font-weight-boldest text-dark-75 mt-15",staticStyle:{"font-size":"150px"}},[_vm._v(" Oops! ")]),_c('p',{staticClass:"display-4 font-weight-light w-30 py-6"},[_vm._v(" Her yere baktık fakat aradığınız sayfayı bulamadık :( ")]),_c('p',{staticClass:"text-dark-50 font-weight-bolder font-size-h6"},[_vm._v("Hata kodu: 404")]),_c('p',{staticClass:"text-dark-50 font-weight-normal font-size-h6 pt-2 mb-1"},[_vm._v("İşte bazı yararlı bağlantılar:")]),_c('router-link',{attrs:{"to":{ name: 'dashboard'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
return [_c('a',{staticClass:"font-weight-normal text-primary font-size-h6 pb-1",attrs:{"href":href}},[_vm._v("Giriş Sayfası")])]}}])}),_c('router-link',{attrs:{"to":{ name: 'experiences'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
return [_c('a',{staticClass:"font-weight-normal text-primary font-size-h6 pb-1",attrs:{"href":href}},[_vm._v("Deneyimler")])]}}])}),_c('router-link',{attrs:{"to":{ name: 'reservations'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
return [_c('a',{staticClass:"font-weight-normal text-primary font-size-h6 pb-1",attrs:{"href":href}},[_vm._v("Rezervasyonlar")])]}}])}),_c('router-link',{attrs:{"to":{ name: 'destinations'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
return [_c('a',{staticClass:"font-weight-normal text-primary font-size-h6 pb-1",attrs:{"href":href}},[_vm._v("Destinasyonlar")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }